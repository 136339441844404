import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {isPlatform} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class SessionDataProviderService {

  constructor( ) {
    console.log('Hello SessionDataProvider Store');

      if ( isPlatform('ios') == true || isPlatform('android')==true){
          console.log("Correct Notification platform");


      }
      else{
          return;
      }
  }
/*
    "account_type": "FULL",
    "added": "2016-09-22 23:26:29.916445",
    "balance": "93468334",
    "ccard": "0",
    "city": "Atlanta3",
    "country": "NG",
    "email": "ses66181+new39@gmail.com",
    "external_idp_provider": "",
    "external_idp_provider_id": "",
    "family_level": "0",
    "firstname": "First68",
    "id": "1",
    "last_login": "2023-08-15 12:58:19.879022",
    "last_login2": "Tuesday   Aug 15, 2023 12:58 PM",
    "lastname": "Last32",
    "loc": "10.10.33.15",
    "member_id": "1",
    "news": "0",
    "online_name": "Online Name61",
    "password": "",
    "phone": "",
    "post_jobs": "2017-07-24 08:33:35.599175",
    "pref_email": "1",
    "pref_phone": "1",
    "profile_pic": "1.jpg",
    "profile_pic_url": "https:\/\/www.wrenchboard.com\/assets\/images\/profile.jpg",
    "promo": "0",
    "refer": "2017-10-30 12:44:06.660273",
    "result": "YES I GET TO BACK END",
    "server_name": "https:\/\/dev-users.wrenchboard.com",
    "session": "0BDEDDAE493E93BD4F4E6F8EB3BC710C1679D66444C4534D3369E54DB63A390A",
    "state": "Georgia",
    "stats_show": "0",
    "status": "1",
    "stauts": "OK",
    "stripe_customer_id": "cus_O6QmTGCmEilwW6",
    "uid": "3119b744-42ad-4834-bb83-b737588754ca",
    "username": "ses66181+1@gmail.com",
    "verify_link": "",
    "zip_code": "30127",
    "internal_return": 100
 */
  max_card:number=4;
  parent_uid:string = '';
  refresh:number=30000;
  account_country:string="NG";
  account_type:string="";
  email: string = "";
  firstname: string = "";
  lastname:string = "";
  session: string = "";
  balance:number =0;
  username:string="";
  last_login:string="";
  member_id:number = 0;
  ccard:number=0;
  isPedningJob: number = 0;
  isOfferJob: number = 0;
  profile_pic:string='';
  profile_pic_url:string='';
  member_uid:string;
  user_state:string;
  city:string;
  account_state:string;
  online_name:string;
  pref_email:number=0;
  pref_phone:number=0;
  promo:number=0;
  active_job_count:number =0;
  tracking:number = 0;
  session_image_server: string='';
  family_uid:string='';
  family_types:any=[];
  history_types:any=[];
  home_dash_type:string="DEFAULT_HOME_DASH";
  family_action:string="";
  account_pref:string="PREF04";
  refer_link:string="";
  generative_types:any=[];

 session_contructed:boolean = false;


 getMonths(){
   // return  ["January","February","March","April","May","June","July",
   //   "August","September","October","November","December"];
   return  [
       [ 1, 'January'],
       [ 2, 'February'],
       [ 3, 'March'],
       [ 4, 'April'],
       [ 5, 'May'],
       [ 6, 'June'],
       [ 7, 'July'],
       [ 8, 'August'],
       [ 9, 'September'],
       [ 10, 'October'],
       [ 11, 'November'],
       [ 12, 'December']
       ];
 }

    getCardExpirationYears(){
        var currY = new Date().getFullYear();
        var arrY = [];

        for(var i= 0; i<=10; i++) {
            arrY.push(currY+i);
        }
        return arrY;
    }
    durationArray = [
        { duration: 1, name: "1 day" },
        { duration: 2, name: "2 days" },
        { duration: 3, name: "3 days" },
        { duration: 4, name: "4 days" },
        { duration: 5, name: "5 days" },
        { duration: 6, name: "6 days" },
        { duration: 7, name: "1 week" },
        { duration: 14, name: "2 weeks" },
        { duration: 21, name: "3 weeks" },
        { duration: 28, name: "4 weeks" },
    ];
 getChildAddYears(){
  var currY = new Date().getFullYear();
  var arrY = [];

   for(var i=4; i<=18; i++) {
     arrY.push(currY-i);
   }

    return arrY;
 }

 getFamilyRoom(){
    return "FAMILY-" + this.parent_uid;
 }

 getFamilyPrivateChannel(){
     return this.getFamilyRoom()+"-"+this.family_uid;
 }

 RefreshGlobalSessionData(loginResult: any){
     this.account_type = loginResult.account_type;
     this.firstname = loginResult.firstname;
     this.lastname = loginResult.lastname;
     this.session = loginResult.session;
     this.session_contructed=loginResult.balance;
     this.email = loginResult.email;
     this.username=loginResult.username;
     this.last_login = loginResult.last_login2;
     // this.profile_pic=loginResult.profile_pic;
     // this.profile_pic_url =  loginResult.profile_pic_url;
     // this.isPedningJob = loginResult.pedningjob;
     // this.isOfferJob = loginResult.offerjob;
      this.user_state=loginResult?.state;
     // this.city = loginResult.city;
     // this.online_name = loginResult.online_name;
     // this.pref_email= loginResult.pref_email;
     // this.pref_phone = loginResult.pref_phone;
     // this.promo=loginResult.promo;
     // this.active_job_count = loginResult.active_job_count;
     // this.tracking = loginResult.tracking;
     // this.session_image_server = loginResult.session_image_server;
     // this.refresh = loginResult.refresh;
     // this.family_types= loginResult.family_types;
     // this.history_types =loginResult.history_types;
     this.home_dash_type = (loginResult.home_dash_type != null)? loginResult.home_dash_type  : 'DEFAULT_HOME_DASH';
     this.family_action =loginResult.family_action;


 }


  ConstructGlobalSessionData(loginResult: any) {
    console.log('Hello ConstructGlobalSessionData Provider');
    this.session_contructed=false;
    try {
      console.log(loginResult);
      this.generative_types = loginResult.generative_types;
      this.account_type = loginResult.account_type;
      this.firstname = loginResult.firstname;
      this.lastname = loginResult.lastname;
      this.session = loginResult.session;
      this.session_contructed=loginResult.balance;
      this.email = loginResult.email;
      this.username=loginResult.username;
      this.last_login = loginResult.last_login2;
      this.member_id = loginResult.member_id;
      this.member_uid = loginResult.uid;
      this.family_uid = loginResult?.family_uid;
      this.balance = loginResult.balance;
      this.ccard=loginResult.ccard;  
      this.profile_pic=loginResult.profile_pic;
      this.profile_pic_url =  loginResult.profile_pic_url;
      this.isPedningJob = loginResult.pedningjob;
      this.isOfferJob = loginResult.offerjob;
      this.user_state=loginResult.state;
      this.city = loginResult.city;
      this.online_name = loginResult.online_name;
      this.pref_email= loginResult.pref_email;
      this.pref_phone = loginResult.pref_phone;
      this.promo=loginResult.promo;
      this.active_job_count = loginResult.active_job_count;
      this.tracking = loginResult.tracking;
      this.session_image_server = loginResult.session_image_server;
      this.refresh = loginResult.refresh;
      this.family_types= loginResult.family_types;
      this.history_types =loginResult.history_types;
      this.home_dash_type = (loginResult.home_dash_type != null)? loginResult.home_dash_type  : 'DEFAULT_HOME_DASH';
      this.family_action =loginResult.family_action;
      //this.pushNotificationProvider.tagUser(this.session);
      this.session_contructed = true;
      this.account_pref = loginResult.account_pref;
      this.refer_link = loginResult.refer_link;

      this.parent_uid =  loginResult.parent_uid != undefined ? loginResult.parent_uid: '';


    } catch (error) {
      console.log("LOGIN STORAGE ERROR->" + error);
    }



    return this.session_contructed;
  }

  DestroySessionOnLogout(){
    console.log('Hello DestroySessionOnLogout Provider');
    this.firstname = "";
    this.lastname = "";
    this.session = "";
    this.session_contructed= false;
    this.username="";
    this.last_login = "";
    this.member_id = -1;
    this.member_uid = "";
    this.balance = -1;
    this.ccard= 0;
    this.profile_pic="";
    this.isPedningJob = 0;
    this.isOfferJob = 0;
  }
  profilePicture(sessionID, memberUID){
    var defualtProfilePicture = "https://www.wrenchboard.com/assets/images/profile.jpg";
    var profile_p = '';
    if (this.profile_pic_url !=''){
      //profile_p="https://www.wrenchboard.com/smedia/LIVE/profile/"+this.profile_pic;
      profile_p = this.profile_pic_url;
    }
    else{
      profile_p = defualtProfilePicture;
    }
    if (this.account_type == 'FAMILY'){
      profile_p = `${environment.baseUrl}/getmedia/${sessionID}/family/${memberUID}`;
    }
    else{
      profile_p = `${environment.baseUrl}/getmedia/${sessionID}/profile/${memberUID}`;
    }
    console.log("profile_p->", profile_p);
    //https://apigate.lotus.g1.wrenchboard.com/en/wrench/api/v1/getmedia/0B6246CE2B89EC948618711E6E6D2DD532797C7F750C69A237FE3F650ECA6B14/profile/3119b744-42ad-4834-bb83-b737588754ca
    //https://apigate.lotus.g1.wrenchboard.com/en/wrench/api/v1/getmedia/81EAF85BBD946518E8CAE81C2CEBCCB8134985203325343F5703E874BED476E2/profile/3119b744-42ad-4834-bb83-b737588754ca
    return profile_p;
  }
}
