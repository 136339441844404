import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { FlutterwaveModule} from "flutterwave-angular-v3"

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, FlutterwaveModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },BnNgIdleService],
  bootstrap: [AppComponent],
})
export class AppModule { }
