import { Component } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import {SessionDataProviderService} from "./store/session-data-provider.service";
import {Router} from "@angular/router"; // import it to your component
import { Platform } from '@ionic/angular';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private bnIdle: BnNgIdleService,
    private sessionDataProviderService : SessionDataProviderService, 
    public router: Router,
    public platform: Platform) {
  //  this.OneSignalInit();
    this.bnIdle.startWatching(2500).subscribe((res) => {
      if(res) {
        console.log("session expired");
       // alert('LOG OUT NOW');
        this.sessionDataProviderService.DestroySessionOnLogout();
        this.router.navigate(['login']);
      }
    });
    this.initializeApp();
  }

  // Call this function when your app starts
  OneSignalInit(): void {
    console.log('Moved to home');
  }

  initializeApp(): void {
    this.platform.ready().then(() => {
      //GoogleAuth.initialize()
    })
  }  
}
