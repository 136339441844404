export const environment = {
  production: false,
  apiKey: '', // <-- Enter your own key here!' TEST
  baseUrl: 'https://apigate.lotus.g1.wrenchboard.com/en/wrench/api/v1',
  baseMediaUrl: 'https://dev-media.wrenchboard.com',
  images: 'https://apigate.lotus.g1.wrenchboard.com/en/wrench/api/v1/',
  appleRedirectUri: 'https://dev-users.wrenchboard.com/loginWithApple',
  socketURL:'https://socket-dev.wrenchboard.com',
  facebookAppId: '677857427521030',
  loginSocial: true,
  keys: {
    googleMapsIOS: 'AIzaSyCvsRozdnd1i1KBGWC6ewe1RaJYuGbrk3s',
    googleMapsAndroid: 'AIzaSyBpDdTIvZ8nAAuI2S6erK3zuvVSKBj-T2k',

  }
};


//AIzaSyBpDdTIvZ8nAAuI2S6erK3zuvVSKBj-T2k  Anriod key
